import axios from 'axios';
import store from "../store/index";

const apiClient = axios.create({
  baseURL: process.env.BACK_END_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Pinggy-No-Screen':'any'
  }
});

export default {
  updateUserProfile(userId, data){
    var formData = new FormData();

    formData.append('userId', userId);
    formData.append("displayName", data.displayName);
    formData.append("avatar", data.avatar);
    formData.append("description", data.description);
    formData.append("showWallet", data.showWallet);
    formData.append("showFriends", data.showFriends);
    formData.append("allowInvites", data.allowInvites);
    formData.append("allowFriendRequests", data.allowFriendRequests);

    apiClient.defaults.headers['Authorization'] = store.state.authtoken;

    return apiClient.post('/user/profile', formData);
  },
  getUser(userId){
    apiClient.defaults.headers['Authorization'] = store.state.authtoken;

    return apiClient.get(`/user/${userId}`);
  },
  getUserProfile(userId, requester){
    apiClient.defaults.headers['Authorization'] = store.state.authtoken;

    return apiClient.get(`/user/profile/${userId}/${requester}`);
  },
  userSearch(userId, searchTerm, page){
    apiClient.defaults.headers['Authorization'] = store.state.authtoken;

    return apiClient.get(`/users/search/${userId}/${searchTerm}/${page}`);
  },
  roomSearch(userId, searchTerm, page){
    apiClient.defaults.headers['Authorization'] = store.state.authtoken;

    return apiClient.get(`/rooms/search/${userId}/${searchTerm}/${page}`);
  },
  getUserFriends(userId){
    apiClient.defaults.headers['Authorization'] = store.state.authtoken;

    return apiClient.get(`/user/friends/${userId}`);
  },
  getPendingFriendRequests(userId){
    apiClient.defaults.headers['Authorization'] = store.state.authtoken;

    return apiClient.get(`user/friends/pending/${userId}`);
  },
  requestFriend(userId, friendId){
    var formData = new FormData();

    formData.append("userId", userId);
    formData.append("friendId", friendId);

    apiClient.defaults.headers['Authorization'] = store.state.authtoken;

    return apiClient.post(`user/friend/request`, formData);
  },

  acceptFriend(userId, friendId){
    var formData = new FormData();

    formData.append("userId", userId);
    formData.append("friendId", friendId);

    apiClient.defaults.headers['Authorization'] = store.state.authtoken;

    return apiClient.post(`user/friend/accept`, formData);

  },

  acceptChat(userId, roomId){
    var formData = new FormData();

    formData.append("userId", userId);
    formData.append("roomId", roomId);

    apiClient.defaults.headers['Authorization'] = store.state.authtoken;

    return apiClient.post(`room/invite/accept`, formData);
  },
  inviteMultipleChat(userId, roomId, invitedIds){
    var formData = new FormData();

    formData.append("userId", userId);
    formData.append("roomId", roomId);
    formData.append("invitedIdsString", JSON.stringify(invitedIds));

    apiClient.defaults.headers['Authorization'] = store.state.authtoken;

    return apiClient.post(`room/invite/multiple`, formData);
  },

  inviteChat(userId, roomId, invitedId){
    var formData = new FormData();

    formData.append("userId", userId);
    formData.append("roomId", roomId);
    formData.append("invitedId", invitedId);

    apiClient.defaults.headers['Authorization'] = store.state.authtoken;

    return apiClient.post(`room/invite`, formData);
  },

  getRadioBrowserStations(filterData){
    var formData = new FormData();

    formData.append("limit", filterData.limit);
    formData.append("by", filterData.by);
    formData.append("searchterm", filterData.searchterm);

    return apiClient.post('/radiobrowser/stations', formData);
  },
  createRoom(roomData){
    var formData = new FormData();

    formData.append("displayName", roomData.displayName);
    formData.append("description", roomData.description);
    formData.append("accessibility", roomData.accessibility);
    formData.append("mediaDataSource", roomData.mediaData.source);
    formData.append("mediaDataId", roomData.mediaData.id);
    formData.append("mediaDataDisplayName", roomData.mediaData.displayName);
    formData.append("mediaDataStreamUrl", roomData.mediaData.streamUrl);
    formData.append("mediaDataCountry", roomData.mediaData.country);
    formData.append("owner", roomData.owner);
    formData.append("file", roomData.roomImg);

    apiClient.defaults.headers['Authorization'] = store.state.authtoken;

    if(roomData.roomImg){
      
      return apiClient.post('/social/room',formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    }
    else  {
      return apiClient.post('/social/room',formData)
    }

  },

  getUserRooms(userId){
    var formData = new FormData();
    
    formData.append("userId", userId);

    apiClient.defaults.headers['Authorization'] = store.state.authtoken;

    return apiClient.get(`/social/userrooms/${userId}`);
    
  },

  startDefiLogin(publicKey){
    var formData = new FormData();

    formData.append("walletAddress", publicKey);

    return apiClient.post('logindefi/start', formData);
  },

  signupDefi(user) {
    var formData = new FormData();

    formData.append("publicKey", user.publicKey);
    formData.append("displayName", user.displayName);
    formData.append("userType", user.userType);
    formData.append("loginType", user.loginType);
    formData.append("signature", user.signature);
    formData.append("signedMessage", user.signedMessage);

    return apiClient.post('/signupdefi', formData);
  },

  loginDefi(user) {
    
    var formData = new FormData();

    formData.append("publicKey", user.publicKey);
    formData.append("loginType", user.loginType);
    formData.append("signature", user.signature);
    formData.append("signedMessage", user.signedMessage);

    return apiClient.post('/logindefi', formData);
  },
  signup(user) {
    var formData = new FormData();

    formData.append("userId", user.email);
    formData.append("displayName", user.displayName);
    formData.append("userType", user.userType);
    formData.append("loginType", user.loginType);

    return apiClient.post('/signup', formData);
  },

  login(user) {
    
    var formData = new FormData();

    formData.append("userId", user.email);
    formData.append("loginType", user.loginType);

    return apiClient.post('/login', formData);
  },

  register(registrationId, referer){
    return apiClient.post(`/registration/${registrationId}`, referer);
  },

  uploadAvatar(avatarData){
    var formData = new FormData();

    formData.append("userId", avatarData.userId);

    formData.append('img', avatarData.img);

    apiClient.defaults.headers['Authorization'] = store.state.authtoken;

    return apiClient.post('/social/avatar', formData, { headers : {
      'Content-Type': 'multipart/formdata'
    }});
  },

  removeProduct(productId){
    return apiClient.post(`/product/remove/${productId}`);
  },

  createProduct(product){

    var formData = new FormData();

    formData.append("name", product.name);
    formData.append("displayName", product.displayName);
    formData.append("available", product.available);
    formData.append("price", product.price);
    formData.append("description", product.description);
    formData.append("tags", product.tags);
    formData.append("imageUrl", product.imageUrl);
    formData.append("thumbnailUrl", product.thumbnailUrl);
    formData.append("dimensions", product.dimensions);
    formData.append("material", product.material);
    formData.append("weight", product.weight);
    formData.append("visible", product.visible);
    formData.append("mainImg", product.mainImg);

    for(let i = 0; i < product.imageFiles.length; i++){
      formData.append("imgFiles", product.imageFiles[i]);
    }

    return apiClient.post('/product/create', formData, {headers: {
      'Content-Type': 'multipart/form-data'
    }});
  },

  getProduct(productId){
    return apiClient.get(`/product/product/${productId}`);
  },

  getProducts(){
    return apiClient.get(`/product/products/`);
  },

  checkAdminStatus(userId){
    

    apiClient.defaults.headers['Authorization'] = store.state.authtoken;

    return apiClient.post(`admin/status/${userId}`);
  },

  updateProduct(product){
    var formData = new FormData();

    formData.append("name", product.name);
    formData.append("displayName", product.displayName);
    formData.append("available", product.available);
    formData.append("price", product.price);
    formData.append("description", product.description);
    formData.append("tags", product.tags);
    formData.append("imageUrl", product.imageUrl);
    formData.append("thumbnailUrl", product.thumbnailUrl);
    formData.append("dimensions", product.dimensions);
    formData.append("material", product.material);
    formData.append("weight", product.weight);
    formData.append("visible", product.visible);
    formData.append("displayData", product.displayData);
    

    return apiClient.post(`/product/update/${product.productId}`, formData);
  },

  calculateShipping(shippingTo, lineItems){
    var formData = new FormData();

    formData.append("shippingTo", shippingTo);
    formData.append("lineItems", lineItems);

    return apiClient.post('/shipping/estimate', formData);
  },

  updateProductByProp(productId, propName, propValue){
    
    var formData = new FormData();

    formData.append("propName", propName);
    formData.append("propValue", propValue);
    
    return apiClient.post(`/product/updateByProp/${productId}`, formData);
  },

  createOrder(order){
    return apiClient.post('/order/create', order);
  },

  getOrder(orderId){
    return apiClient.get(`/order/order/${orderId}`);
  },

  getOrders(){
    return apiClient.get(`/order/orders/`);
  },

  updateOrder(orderId, order){
    return apiClient.post(`/order/update/${orderId}`, order);
  },

  updateOrderByProp(orderId, propName, propValue){
    
    var formData = new FormData();

    formData.append("propName", propName);
    formData.append("propValue", propValue);
    return apiClient.post(`/order/updateByProp/${orderId}`, formData);
  },

  adminLogin(username, password){
    var formData = new FormData();

    formData.append("username", username);
    formData.append("password", password);

    return apiClient.post("/admin/login", formData);
  },

  sendCustomRequest(formName, formEmail, formPhone, formBudget, formDate, formNotes, formFile) {
    var formData = new FormData();

    formData.append("name", formName);
    formData.append("email", formEmail);
    formData.append("phone", formPhone);
    formData.append("budget", formBudget);
    formData.append("date", formDate);
    formData.append("notes", formNotes);
    formData.append("file", formFile);

    if(formFile){
      
      return apiClient.post('/customrequest',formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    }
    else  {
      return apiClient.post('/customrequest',formData)
    }
  },

  sendContact(formName, formEmail, formPhone, formMessage) {
    var formData = new FormData();

    formData.append("name", formName);
    formData.append("email", formEmail);
    formData.append("phone", formPhone);
    formData.append("message", formMessage);

    return apiClient.post('/contact', formData);
  }
};
