<template>
    <div class="profile-container">
        <div v-if="editingProfile" class="edit-profile-container">
            <div class="edit-profile-form">
                <div class="edit-profile-row">
                    <input type="text" v-model="profileEditValues.displayName" @input="sanitizeDisplayName" class="edit-profile-input" placeholder="Display Name" maxlength="20"/>
                </div>
                <div class="edit-profile-row">
                    <div :style="{ backgroundImage:`url(${avatarBaseUrl}${user.avatar == 'custom' ? id : user.avatar}.webp)` }" class="edit-profile-avatar"></div>
                    <input  v-on:change="avatarUpdated" name="img" type="file" accept=".png, .jpg, .jpeg, .webp"/>

                    
                </div>
                <div class="edit-profile-row">
                    <div v-on:click="uploadAvatarImg" class="upload-avatar-btn edit-profile-btn">Upload</div>
                    <div v-on:click="removeAvatarImg" class="upload-avatar-btn edit-profile-btn">Remove</div>
                </div>
                <div class="edit-profile-row">
                    <textarea type="text" v-model="profileEditValues.description" placeholder="Summary" maxlength="300"></textarea>
                </div>
                <div class="edit-profile-row">
                    <input type="checkbox" v-model="profileEditValues.showWallet"/>
                    <div class="edit-profile-label">Show Wallet</div>
                    
                </div>
                <div class="edit-profile-row">
                    <input type="checkbox" v-model="profileEditValues.showFriends"/>
                    <div class="edit-profile-label">Show Friends</div>
                    
                    
                </div>
                <div class="edit-profile-row">
                    <input type="checkbox" v-model="profileEditValues.allowInvites"/>
                    <div class="edit-profile-label">Allow Invites</div>
                    
                </div>
                <div class="edit-profile-row">
                    <input type="checkbox" v-model="profileEditValues.allowFriendRequests"/>
                    <div class="edit-profile-label">Allow Friend Requests</div>
                    
                </div>
                <div class="edit-profile-row">
                    <div v-on:click="saveProfile" class="save-profile-btn edit-profile-btn">Save</div>
                    <div v-on:click="cancelProfile" class="cancel-profile-btn  edit-profile-btn">Cancel</div>
                </div>
            </div>
        </div>
        <div class='profile-web3-container'>
            
        </div>
        <div class='profile-main-container'>
            <div class="profile-avatar-box">
                <div class="profile-avatar" :style="{ backgroundImage:`url(${avatarBaseUrl}${user.avatar == 'custom' ? id : user.avatar}.webp)` }"></div>
                <div class="profile-display-name">{{ user.displayName }}</div>
                
                <div v-on:click="openProfileEdit" v-if="$store.state.user.userId == user.userId" class="edit-profile-link">Edit Profile</div>
                <div class="profile-summary">{{  user.description }}</div>
            </div>
            <div class="friends-box">
                <div class="box-title">
                    <div class="box-title-text">Friends</div>
                    <div v-show="!friendsExpanded" v-on:click="toggleFriendsExpansion" class="box-title-expand">
                        <i class="fa-solid fa-plus"></i>
                       
                    </div>
                    <div v-show="friendsExpanded" v-on:click="toggleFriendsExpansion" class="box-title-expand">
           
                        <i  class="fa-solid fa-minus"></i>
                    </div>
                    
                </div>
                <div :class="{expanded: friendsExpanded}" class="box-contents">
                    <div v-on:click="goToProfile(friend.userId)" class="friend-item" v-for="friend in friends" :key="friend.userId">
                        <div class="friend-avatar" :style="{backgroundImage: `url(${avatarBaseUrl}${friend.userId}.webp)`}"></div>
                        <div class="friend-displayname">{{ friend.displayName }}</div>
                    </div>
                </div>
            </div>
            <div class="profile-wallet-box">    
                    <div class="box-title">
                        <div class="box-title-text">Wallet</div>
                        <!-- v-show for fontawesome -->
                        <div v-show="!walletExpanded" v-on:click="toggleWalletExpansion" class="box-title-expand plus">
                            <i  class="fa-solid fa-plus"></i>
                        </div>
                        <div v-show="walletExpanded" v-on:click="toggleWalletExpansion" class="box-title-expand minus">
                            
                            <i class="fa-solid fa-minus"></i>
                        </div>
                    </div>
                    <div :class="{expanded:walletExpanded}" class="box-contents">
                        <div class="box-contents-row">
                            <div class="contents-row-label">{{ 'SOL :' }}</div>
                            <div class="contents-row-value">{{ solBalance }}</div>
                        </div>
                    </div>
            </div>
            <div class="profile-stats-box">
                <div class="box-title">
                    <div class="box-title-text">Stats</div>
                    <div v-show="!statsExpanded" v-on:click="toggleStatsExpansion" class="box-title-expand">
                        <i class="fa-solid fa-plus"></i>
                       
                    </div>
                    <div v-show="statsExpanded" v-on:click="toggleStatsExpansion" class="box-title-expand">
           
                        <i  class="fa-solid fa-minus"></i>
                    </div>
                    
                </div>
                <div :class="{expanded: statsExpanded}" class="box-contents">

                </div>
            </div>
        </div>
        <div class='profile-social-container'>
            
        </div>
    </div>
</template>

<script>
import socialModule from '../module/social';
import api from '../api/app';
import { mapState } from 'vuex';
import wallet from '../module/wallet';

export default {
    data() {
      return {
        avatarImgFile: {},
        editingProfile: false,
        avatarBaseUrl: process.env.VUE_APP_AVATAR_BASE_URL,
        walletExpanded: false,
        statsExpanded: false,
        friendsExpanded: false,
        solBalance: 0,
        user: {},
        customImgAdded: false,
        customImgRemoved: false,
        profileEditValues: {
            displayName: "",
            avatar : "",
            description: "",
            showWallet: false,
            showFriends: false,
            allowInvites: false,
            allowFriendRequests: false
        },
        uploadingAvatar: false,
        updatingUser : false,
        id: null,
        friends: []

      }
    },
    created() {
        this.id = this.$route.params.id;
    },
    mounted: async function(){
        this.loadPage();
        
    },
    watch: {
        '$route.params.id': {
            handler(newId){
                if(newId != this.id){
                    this.id = newId;
                    this.loadPage();

                }
            },
            immediate:true
        }
    },
    methods: {
         async loadPage(){
            this.walletExpanded= false;
            this.statsExpanded= false;
            this.friendsExpanded= false;
            if(this.id){
                this.loadUserData();
            }
            
            if(this.user.showWallet){
                this.solBalance = await wallet.getSolanaBalance(this.user.userId);
                console.log('balance : ' + this.solBalance);
            }
        },
        async loadUserData(store){
            api.getUserProfile(this.id, this.$store.state.user.userId).then((response) => {
                this.user = response.data.user;
                this.friends = response.data.friends;
                if(store){
                    this.$store.dispatch('updateUser', response.data.user);
                }
            })
            .catch((e) => {
                console.error(e);
            });
        },
        goToProfile(newId){
            this.$router.push({name: 'social_profile', params : {id: newId}}).catch(()=>{});
        },
        toggleWalletExpansion(){
            this.walletExpanded = !this.walletExpanded;
        },
        toggleStatsExpansion(){
            this.statsExpanded = !this.statsExpanded;
        },
        toggleFriendsExpansion(){
            this.friendsExpanded = !this.friendsExpanded;
        },
        removeAvatarImg(){
            this.customImgRemoved = true;
        },
        uploadAvatarImg(){
            var self = this;
            this.uploadingAvatar = true;
            var data = {
                userId : this.$store.state.user.userId,
                img: this.avatarImgFile
            };

            api.uploadAvatar(data).then(function(response){
                console.log("upload success yayz")
                self.customImgAdded = true;
                self.uploadingAvatar = false;
            }).catch(function(error){
                console.log(error);
                self.uploadingAvatar = false;
            })
            
        },
        avatarUpdated(event){
            this.avatarImgFile = event.target.files[0];
        },
        sanitizeDisplayName(){
            this.profileEditValues.displayName = this.profileEditValues.displayName.replace(/[^a-zA-Z0-9]/g, "");
        },
        saveProfile(){
            var self = this;
            var requestData = {
                displayName: this.profileEditValues.displayName || this.user.displayName,
                avatar : this.customImgAdded ? 'custom' : (this.customImgRemoved ? 'default1' : this.user.avatar),
                description : this.profileEditValues.description,
                showWallet: this.profileEditValues.showWallet,
                showFriends: this.profileEditValues.showFriends,
                allowInvites: this.profileEditValues.allowInvites,
                allowFriendRequests: this.profileEditValues.allowFriendRequests,
            };

            api.updateUserProfile(this.user.userId, requestData).then((response) => {
                self.loadUserData(true);
                
                self.customImgAdded = false;
                self.customImgRemoved = false;
                self.editingProfile = false;
            })
            .catch((e) => {
                if(e.response && e.response.status && e.response.status == '401'){
                    self.$store.dispatch("logout");
                }
                console.error(e);
            })
        },
        cancelProfile(){
            this.profileEditValues = {
                displayName: "",
                avatar : "",
                description: "",
                showWallet: false,
                showFriends: false,
                allowInvites: false,
                allowFriendRequests: false
            };
            this.customImgAdded = false;
            this.customImgRemoved = false;

            this.editingProfile = false;
        },
        openProfileEdit(){
            this.profileEditValues.displayName = this.user.displayName;
            this.profileEditValues.avatar = this.user.avatar;
            this.profileEditValues.description = this.user.description;
            this.profileEditValues.showWallet = this.user.showWallet;
            this.profileEditValues.showFriends = this.user.showFriends;
            this.profileEditValues.allowInvites = this.user.allowInvites;
            this.profileEditValues.allowFriendRequests = this.user.allowFriendRequests;

            this.editingProfile = true;
        }
    }
}
</script>

<style>
/*profile*/
.profile-container{
        display:flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        max-height:100%;
        transition: all 250ms ease-in-out;
        box-sizing: border-box;
        position:absolute;
        justify-content: center;
        align-items: center;
        padding-top:4em;
        overflow:hidden;
    }

    .profile-container.inactive{
        max-height:0%;
        transition: all 250ms ease-in-out;
        padding: 0;
    }

    .profile-web3-container{
        flex: 0 0 20%;
        box-sizing: border-box;
        padding: 10em 2em 2em;
        display: flex;
        flex-direction: column;
        height:100%;
    }

    .profile-social-container{
        height:100%;
        flex: 0 0 20%;
        box-sizing: border-box;
        padding: 10em 2em 2em;
        display: flex;
        flex-direction: column;
    }

    .profile-main-container{
        height:100%;
        flex: 1;
        padding: 2em 5em;
        display:flex;
        flex-direction: column;
        gap: 2em;
        overflow-y:scroll;
        align-items:center;

    }

    .profile-summary{
        color:white;
        font-size: 1em;
        margin-top:2em;
        max-width:30em;

    }
    .profile-avatar-box{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .friends-box{
        box-sizing: border-box;
        border: .2em solid white;
        border-radius: .3em;
        padding: 1em;
        max-width:20em;
        min-width:20em;
    }

    .profile-wallet-box{
        box-sizing: border-box;
        border: .2em solid white;
        border-radius: .3em;
        padding: 1em;
        max-width:20em;
        min-width:20em;
    }

    .profile-stats-box{
        box-sizing: border-box;
        border: .2em solid white;
        border-radius: .3em;
        padding: 1em;
        max-width:20em;
        min-width:20em;
    }

    .box-title{
        color: white;
        font-weight: bold;
        font-size: 1.5em;
        width: 100%;
        text-align: left;
        display:flex;
        flex-direction: row;
    }

    .box-title-expand{
        color:white;
        margin-left:auto;
        cursor:pointer;
        transition: all 100ms ease-in-out;
    }

    .box-title-expand:hover{
        filter:drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 6px white);
        transition: all 100ms ease-in-out;
    }

    .friend-item{
        display:flex;
        flex-direction: row;
        align-items: center;
        gap:.5em;
        cursor:pointer;
    }

    .friend-avatar{
        width: 2em;
        height:2em;
        background-repeat:no-repeat;
        background-position: center;
        background-size:cover;
        border: .1em solid white;
        border-radius: 100%;
    }

    .friend-displayname{
        font-weight:bold;
        color:white;
        font-size: .9em;
    }

    .box-contents{
        min-height:0em;
        max-height:0em;
        transition: all 250ms ease-in-out;
        width: 100%;
        display:flex;
        flex-direction: column;
        overflow:hidden;
    }

    .box-contents.expanded{
        margin-top:1em;
        min-height:10em;
        max-height:10em;
        transition: all 250ms ease-in-out;
    }

    .box-contents-row{
        width:100%;
        display:flex;
        flex-direction: row;
        color:white;
        font-weight: bold;
        font-size: 1.1em;
    }

    .contents-row-label{
        text-align: left;
    }

    .contents-row-value{
        text-align: right;
        margin-left:auto;
    }

    .profile-display-name{
        color: white;
        font-weight:bold;
        font-size: 1.8em;
        margin-top: .5em;
    }

    .profile-avatar{
        width: 8em;
        height:8em;
        background-repeat:no-repeat;
        background-position: center;
        background-size:cover;
        border: .3em solid white;
        border-radius: 100%;
    }

    .edit-profile-link{
        font-size: .7em;
        color: blue;
        text-decoration: underline;
        cursor: pointer;
        transition:all 100ms ease-in-out;
    }

    .edit-profile-link:hover{
        color: rgb(0, 100, 255);
        transition:all 100ms ease-in-out;
    }
    .edit-profile-container{
        width:100%;
        height:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        z-index: 4;
        position:absolute;
        top:0;
        left:0;
        background-color:rgb(0, 0, 0, .8);
    }

    .edit-profile-form{
        width: 20em;
        display:flex;
        max-height:100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2em;
        background-color: #444;
        gap: 1em;
    }

    .edit-profile-row{
        width: 100%;
        flex: 0 0 2em;
        gap: 1em;
        display:flex;
        flex-direction: row;
        align-items: center;
    }

    .edit-profile-input{
        width: 100%;
    }

    .edit-profile-label{
        margin-right: auto;
        color:white;
    }

    .edit-profile-avatar{
        width: 2em;
        height:2em;
        background-repeat:no-repeat;
        background-position: center;
        background-size:cover;
        border: .3em solid white;
        border-radius: 100%;
    }

    .edit-profile-btn{
        display:flex;
        align-items: center;
        justify-content: center;
        font-weight:bold;
        background-color:rgb(255, 255, 255, .2);
        transition: all 100ms ease-in-out;
        border-radius: .4em;
        color:white;
        cursor:pointer;
        padding: .4em;
    }

    .edit-profile-btn:hover{
        background-color:rgb(255, 255, 255, .4);
        transition: all 100ms ease-in-out;
    }
    /*profile*/
    </style>