// src/store/index.js

import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
import login from '../api/signin'
import signup from '../api/signup'
import utils from '../utils'
import storage from './storage'

Vue.use(Vuex)
var userTime = 14400000;
var store = new Vuex.Store({
  state: {
    user: storage.get('user') ? storage.get('user') : null,
    cart: storage.get('cart') ? storage.get('cart') : [],
    admin: storage.get('admin') ? storage.get('admin') : null,
    rooms: storage.get('rooms') ? storage.get('rooms') : {},
    friendrequests: storage.get('friendrequests') ? storage.get('friendrequests') : [],
    roominvites: storage.get('roominvites') ? storage.get('roominvites') : [],
    authtoken: storage.get('auth_token') ? storage.get('auth_token') : '',
    newRoom: "",
    socialconnected: false
  },
  mutations: {
    CONNECT_SOCIAL(state){
      state.socialconnected = true;
    },
    ADDED_NEW_ROOM(state, roomId){
      state.newRoom = roomId;
    },

    UPDATE_FRIEND_REQUESTS(state, friendRequests){
      state.friendrequests = [];
      friendRequests.forEach((request) => {
        state.friendrequests.push(request);
      });
      storage.set('friendrequests', state.friendrequests);
    },
    UPDATE_ROOM_INVITES(state, roomInvites){
      state.roominvites = [];
      roomInvites.forEach((invite, index) => {
        console.log(index);
        state.roominvites.push(invite);
      });
      storage.set('roominvites', state.roominvites);
    },
    LOAD_ROOMS(state, roomList){
      roomList.forEach((room) => {
        Vue.set(state.rooms, room.roomId, room);
      });
      storage.set('rooms', state.rooms);
    },
    ADD_ROOM(state, {room}){
        Vue.set(state.rooms, room.roomId, room);
        
        storage.set('rooms', state.rooms);
      
    },
    ADD_MESSAGE_TO_ROOM(state, {message}){
      var tempRoom = {};
      if(!state.rooms[message.roomId]){
        tempRoom = {
          messages : []
        }
      }
      else{
        tempRoom = {...state.rooms[message.roomId]};
        if(!tempRoom['messages']){
          tempRoom['messages'] = [];
        }
      }
      
      tempRoom.messages.push(message);

      Vue.set(state.rooms, message.roomId, tempRoom);
      
      storage.set('rooms', state.rooms);

    },
    UPDATE_ROOM(state, room){
      Vue.set(state.rooms, room.roomId, room);

      storage.set('rooms', state.rooms);
    },
    SET_ADMIN(state, admin){
      state.admin = admin;
      storage.set('admin', admin, 120);
    },
    SET_USER(state, user) {
      var userData = {...user};
      userData['expiresAt'] = Date.now() + userTime;

      state.user = userData;
      storage.set('user', userData, 120); // Set cookie to expire in 2 hours
    },
    CLEAR_USER(state) {
      state.user = null;
      storage.remove('user');
    },
    CLEAR_admin(state){
      state.admin = null;
      storage.remove('admin');
    },
    SET_CART(state, cart){
      state.cart = cart;
      storage.set('cart', cart, 120);
    },
    CART_ADD(state, cartItem){

      var cartItemIndex = state.cart.findIndex( x => x.productId === cartItem.productId);
      var foundMatch = false;
      if(cartItemIndex == -1){
        cartItem['itemId'] = state.cart.length + 1;
        state.cart.push(cartItem);
      }
      else{

        state.cart.forEach((element, index) => {
          //check custom fields
          if(utils.productsAreEqual(element, cartItem)){
            var temp = state.cart[cartItemIndex];
            temp.quantity = state.cart[cartItemIndex].quantity + cartItem.quantity;
            state.cart.splice(cartItemIndex, 1);
            state.cart.push(temp);
            foundMatch = true;
          }
        });

        if(!foundMatch){
          cartItem['itemId'] = state.cart.length + 1;
          state.cart.push(cartItem);
        }
        
        //Vue.set(state.cart[cartItemIndex], state.cart[cartItemIndex]['quantity'], (state.cart[cartItemIndex].quantity + cartItem.quantity));
        //state.cart[cartItemIndex].quantity = state.cart[cartItemIndex].quantity + cartItem.quantity;
      }
      storage.set('cart', state.cart, 120);
      
    },

    CART_REMOVE(state, cartItem){
      var cartItemIndex = state.cart.findIndex( x => x.productId === cartItem.productId);

      if(cartItemIndex != -1){
        state.cart.splice(cartItemIndex, 1);
      }
      
      storage.set('cart', state.cart, 120);
    },

    CLEAR_CART(state){
      state.cart = [];
      storage.remove('cart');
    },
    SET_AUTH_TOKEN(state, token){
      state.authtoken = token;

      storage.set('auth_token', state.authtoken);
    }
  },
  getters :{
    roomInvitesUnseen(state){
      return state.roominvites.filter(invite => invite.seen == false).length;
    },
    friendRequestsUnseen(state){
      return state.friendrequests.filter(request => request.seen == false).length;
    },
    rooms(state){
      return state.rooms;
    },
    room : (state) => (id) => {
      return state.rooms[id];
    },
    roomMessages: (state) => (id) => {
      if(state.rooms[id]){
        return state.rooms[id].messages;
      }
      return [];
    },
    user(state){

    }, 
    roomInvites(state){
      return state.roominvites;
    },
    friendRequests(state){
      return state.friendrequests;
    },
    isLoggedIn(state){
      return !!state.user;
    },
    cartContents(state){
      return state.cart;
    },
    adminStatus(state){
      return state.admin;
    },
    cartTotal(state){
      if(!state.cart || state.cart.length == 0){
        return 0;
      }
      
      var quantityArray = state.cart.map(p => (p.price * p.quantity));

      var sum = quantityArray.reduce((accumulator, currentValue) => {
        return accumulator + currentValue
      }, 0)

      return sum;
    },
    cartQuantity(state){
      if(!state.cart || state.cart.length == 0){
        return 0;
      }
      
      var quantityArray = state.cart.map(p => p.quantity);

      var sum = quantityArray.reduce((accumulator, currentValue) => {
        return accumulator + currentValue
      }, 0)

      return sum;
    }
  },
  actions: {
    async login({ commit }, user) {
      const response = await login.login(user);
      if(response.data.status == "login_successful"){
        commit('SET_USER', JSON.parse(response.data.userData));
      }
      return response;
      
    },
    async register({ commit }, user) {
      const response = await signup.signup(user);
      if(response.data.status == "signup_successful"){
        commit('SET_USER', JSON.parse(response.data.userData));
      }
      return response;
    },
    async logindefi({ commit }, user) {
      const response = await login.loginDefi(user);
      if(response.data.status == "login_successful"){
        commit('SET_AUTH_TOKEN', response.data.authToken);
        commit('SET_USER', JSON.parse(response.data.userData));
      }
      return response;
      
    },
    async registerdefi({ commit }, user) {
      const response = await signup.signupDefi(user);
      if(response.data.status == "signup_successful"){
        commit('SET_AUTH_TOKEN', response.data.authToken);
        commit('SET_USER', JSON.parse(response.data.userData));
      }
      return response;
    },
    updateRoomInvites({commit}, invites){
      commit('UPDATE_ROOM_INVITES', invites);
    },
    updateFriendRequests({commit}, requests){
      commit('UPDATE_FRIEND_REQUESTS', requests);
    },
    loadRooms({commit}, roomList){
      commit('LOAD_ROOMS', roomList);
    },
    addRoom({commit}, {room}){
      
      commit('ADD_ROOM', {room});
    },
    addMessageToRoom({commit}, {message}){
      commit('ADD_MESSAGE_TO_ROOM', {message});
    },
    updateRoom({commit}, room){
      commit('UPDATE_ROOM', room);
    },
    logoutAdmin({ commit }, admin){
      commit('CLEAR_ADMIN');
    },
    logout({ commit }) {
      commit('CLEAR_USER');
    },
    addCartItem({ commit }, cartItem) {
      commit('CART_ADD', cartItem);
    },
    removeCartItem({commit}, cartItem){
      commit('CART_REMOVE', cartItem);
    },
    clearCart({commit}){
      commit('CLEAR_CART');
    },
    updateUser({commit}, user){
      commit('SET_USER', user);
    },
    setAuthToken({commit}, token){
      commit('SET_AUTH_TOKEN', token);
    },
    addedNewRoom({commit}, room){
      commit('UPDATE_ROOM', room);
      commit('ADDED_NEW_ROOM', room.roomId);
    },
    connectSocial({commit}){
      commit('CONNECT_SOCIAL');
    }

  }
});



var timer = window.setInterval(function(){
  if(store.state.user && store.state.user.expiresAt < Date.now()){
    store.dispatch('logout');
  }
}, 1000);

export default store;
