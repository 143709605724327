<template>
    <div class="social-wrap">
        <div v-if="!$store.state.user" class="login-text">Please login to use Social features</div>
        <div v-if="$store.state.user" class="social-container">
            <div class="social-views-container">
                <!--<UsersPage :class="activeSocialView == 'users' ? '' : 'inactive'" class="social-view-container">
                    
                </UsersPage>
                <RoomsPage ref="roomsPage" :class="activeSocialView == 'chat' ? '' : 'inactive'" class="chat-module social-view-container">
                    
                    
                </RoomsPage>
                <div :class="activeSocialView == 'create' ? '' : 'inactive'" class="create-container social-view-container">
                    Create
                </div>
                <div :class="activeSocialView == 'slides' ? '' : 'inactive'" class="slides-container social-view-container">
                    Posts
                </div>
                <ProfilePage v-bind="{id: this.$store.state.user.userId}" :class="activeSocialView == 'profile' ? '' : 'inactive'">
                </ProfilePage>-->

                <router-view :sModule="socialModule" />
            </div>
            <div class="social-control-tray">
                <div v-on:click="setActiveSocialView('social_front')" class="profile-control-btn social-control-btn"><i class="fa-solid fa-home"></i><div>Home</div></div>
                <div v-on:click="setActiveSocialView('social_users')" class="users-control-btn social-control-btn">
                    <div v-show="friendRequestsQuant > 0" class="notifications-quant">{{ friendRequestsQuant }}</div>    
                    <i class="fa-solid fa-users"></i><div>Peeps</div>
                </div>
                <div v-on:click="setActiveSocialView('social_rooms')" class="chat-control-btn social-control-btn">
                    <div v-show="roomInvitesQuant > 0" class="notifications-quant">{{ roomInvitesQuant }}</div>    
                    <i class="fa-solid fa-comments"></i><div>Chats</div>
                </div>
                <div v-on:click="setActiveSocialView('social_create')" class="create-control-btn social-control-btn"><i class="fa-solid fa-video"></i><div>Create</div></div>
                <div v-on:click="setActiveSocialView('social_posts')" class="slides-control-btn social-control-btn"><i class="fa-solid fa-images"></i><div>Posts</div></div>
            </div>
        </div>
    </div>
</template>

<script>
import UsersPage from '../../components/UsersPage';
import ProfilePage from '../../components/ProfilePage';
import RoomsPage from '../../components/RoomsPage';
import socialModule from '../../module/social';
import api from '../../api/app';
import { mapState } from 'vuex';

    export default {
        
      
    data() {
      return {
        activeSocialView : 'profile',
        socialInitiated: false,
        socialModule: null
      };
    },
      mounted: function(){
          if(this.$store.state.user){
            socialModule.init();
        
          
          }
          
          //this.$router.push({name: "social_front"}).catch(()=>{});
          
        
      },
      computed: {
          getClientId: function(){
              return process.env.VUE_APP_CLIENT_ID;
          },
          ...mapState(['rooms']),
          ...mapState(['user']),
          ...mapState(['newRoom']),
          roomInvitesQuant: function(){
            return this.$store.getters.roomInvitesUnseen;
          },
          friendRequestsQuant: function(){
            return this.$store.getters.friendRequestsUnseen;
          }
          
      },
      watch: {
        user(){
            if(this.$store.state.user){
                if(!this.socialInitiated){
                    socialModule.init();
                    this.socialModule = socialModule;
                    this.socialInitiated = true;
                }
                
            }
        },
        newRoom(){
            if(this.$store.state.newRoom && this.$store.state.newRoom !== ""){
                this.$router.push({name: 'social_room', params : {id: this.$store.state.newRoom}}).catch(()=>{});
            }
        }
        
                
            
        },
      methods: {
        
        
        setActiveSocialView(newView){
            if(newView == 'social_profile'){
                this.$router.push({name: 'social_profile', params : {id: this.$store.state.user.userId}}).catch(()=>{});
            }
            else{
                this.$router.push({name: newView}).catch(()=>{});
            }
            this.activeSocialView = newView;

        },

        
      }
    }
</script>

<style scoped>
    .login-text{
        height:100%;
        width:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size: 2em;
    }
    .social-wrap{
        height:100%;
        width:100%;
        flex:1;
        display:flex;
        flex-direction:column;
        z-index:3;

    }
    .social-container{
        height:100%;
        width:100%;
        display:flex;
        flex-direction: column;
        align-items: stretch;
        flex:1;

    }

    .social-control-tray{
        width:100%;
        flex: 0 0 3em;
        display:flex;
        flex-direction: row;
        align-items: center;
        position:fixed;
        bottom:0;

    }
    .social-control-btn{
        height:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 1 1 0px;
        gap: .5em;
        font-size: 1em;
        
        background-color:black;
        color:white;

        transition: all 200ms ease-in-out;

        cursor: pointer;
        position:relative;
    }

    .social-control-btn:active{
        background-color: #444;
        transition: all 100ms ease-in-out;
    }
    .social-control-btn:hover{
        background-color: #111;
        transition: all 200ms ease-in-out;
    }

    .social-views-container{
        flex: 1;
        display:flex;
        justify-content: center;
        position:relative;
    }
    .social-view-container{
        width:100%;
        height:100%;
        max-height:100%;
        transition: all 250ms ease-in-out;
        display: flex;
        flex-direction:column;
        color:white;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        overflow:hidden;
        position:absolute;
    }

    .social-view-container.inactive{
        max-height: 0px;
        padding: 0;
        
        transition: all 250ms ease-in-out;
    }

 
    .upload-avatar-btn{
        border: 1px solid white;
        padding: .3em;
        font-weight: bold;
        color: white;
        cursor: pointer;
        transition: all 100ms ease-in-out;
    }

    .upload-avatar-btn:hover{
        background-color: rgb(255, 255, 255, .3);
        transition: all 100ms ease-in-out;
    }

    .notifications-quant{
        width:1em;
        height:.8em;
        border-radius:.4em;
        background-color:red;
        position:absolute;
        top:0;
        left: calc(50% + 1.5em);
        display:flex;
        align-items: center;
        justify-content: center;
        font-weight:bold;
        font-size:.7em;
        padding:.2em;
    }

    
</style>