<template>
    <div class="about-container">
        <div class="back-btn"></div>
        <div class="about-title">What is GriddWork?</div>
        <div class="about-body">
            <div class="about-subtitle"></div>
            <div class="about-text">
                GriddWork is an emerging social platform where the users ultimately determine how it develops. Current 
                and future features will be designed to give users control over the content, appearance, and organization 
                of the hubs they create and use. 
            </div>
        </div>
    </div>
</template>

<script>
export default {
        
      
        data() {
          return {
            
          };
        },

        methods: {
            goToFront(){
                this.$router.push({name: "social_front"}).catch(()=>{});
            }


        }
    }
</script>

<style>
    .about-container{
        width:100%;
        height:100%;
        display:flex;
        justify-content: center;
        padding: 2em;
        flex-direction: column;
        color:white;
    }

    .back-btn{
        position:absolute;
        top: 1em;
        left: 1em;
        cursor: pointer;
        height: 1em;
        width: 1em;
    }

    .about-title{
        font-weight:bold;
        font-size: 3em;
        margin-bottom: 2em;
    }

    .about-text{
        font-size: 1em;
        padding: 2em;
        
    }
</style>