<template>
    <div class="faq-container">
        <div class="faq-title">FAQ</div>
        <div class="faq-body">
            <div class="faq-item">
                <div class="faq-question">How is GriddWork different from platforms like Reddit?</div>
                <div class="faq-answer">While other platforms may offer freedom of space creation, 
                    they can often still be quite limited. Eventually GriddWork will allow users to have 
                    unmatched personalization of themes, content, organization, promotion, and the very ownership of 
                    the spaces they create or own. 
                </div>
            </div>
            <div class="faq-item">
                <div class="faq-question">Is it Just chat rooms?</div>
                <div class="faq-answer">Chat rooms are the first major feature we have released. While we are still 
                    adding on to the subfeatures in Chat (Including more customization, media options, and our own 
                    video streaming systems), we will also be adding many other ways to have discussions, build spaces 
                    for similary inclined users, and get the latest news from your favorite sources.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
        
      
        data() {
          return {
          };
        },

        methods: {
            goToFront(){
                this.$router.push({name: "social_front"}).catch(()=>{});
            }


        }
    }
</script>

<style>
.faq-container{
    width:100%;
    height: 100%;
    display:flex;
    flex-direction:column;
    padding: 2em;
    gap: 2em;
    color:white;
    box-sizing: border-box;
    justify-content: center;
    text-align:left;
}

.faq-title{
    font-size: 3em;
    font-weight: bold;
}

.faq-body{
    width: 100%;
    display:flex;
    flex-direction: column;
    gap : 2em;
    padding: 2em;
    box-sizing: border-box
}

.faq-item{
    width: 100%;
    text-align: left;
    display:flex;
    flex-direction: column;
    gap:.2em;
}

.faq-question{
    font-weight:bold;
    font-size: 1.5em;

}

.faq-answer{
    font-size: 1em;
}
</style>