import { io } from "socket.io-client";
import SimplePeer from "simple-peer";
import store from "../store/index";


    

export default{
    initiated: false,
    socket: {},
    init(){
           
            this.socket = io(process.env.BACK_END_URL, {
                query: {
                    userId: store.state.user.userId,
                    authToken: store.state.authtoken
                }
            });
            this.initiated = true;

            this.socket.on("chat-history", (messages) => {
                //this.chatMessages = messages;
            });
        
            // Listen for new messages
            this.socket.on("receive-message", (message) => {
               
                store.dispatch("addMessageToRoom", {message});
            });

            this.socket.on("joined-room", (room) => {
                store.dispatch("addRoom", {room});
            });

            this.socket.on("update-room-meta", (room) => {
  
                store.dispatch("updateRoom", room);
            });

            this.socket.on('user-updated', (user) => {
                
                store.dispatch("updateUser", user);
            });

            this.socket.on('update-room-invites', (invites) => {
                store.dispatch("updateRoomInvites", invites);
            });

            this.socket.on('update-friend-requests', (requests) => {
                
                store.dispatch("updateFriendRequests", requests);
            });

            this.socket.on("room-added", (room) => {
                store.dispatch("addedNewRoom", room);
            });

            this.socket.on("is-connected", () => {
                store.dispatch("connectSocial");
            });
        

    },
    acceptInvite(roomId, userId){
        if(roomId && userId){
            this.socket.emit('accept-invite', {roomId : roomId, userId : userId});
        }
    },
    declineInvite(roomId, userId){
        if(roomId && userId){
            this.socket.emit('decline-invite', {roomId : roomId, userId : userId});
        }
    },
    acceptFriendRequest(userId, friendId){
        if(userId && friendId){
            this.socket.emit("accept-friend-request", {userId : userId, friendId: friendId});
        }
    },
    seeFriendRequest(userId, friendId){
        if(userId && friendId){
            this.socket.emit("see-friend-request", {userId: userId, friendId: friendId});
        }
    },
    seeRoomInvite(roomId, userId){
        if(roomId && userId){
            this.socket.emit("see-room-invite", {roomId: roomId, userId: userId});
        }
    },
    updateUserMeta(key, value){
        
        if(key && (value || value == 0)){
            this.socket.emit("update-user-meta", {userId : store.state.user.userId, key : key, value : value});
        }
    },
    closeRoom(roomId){
        if(roomId){
            this.socket.emit("close-room", roomId);
        }
    },
    createRoom(displayName, description ){
        if(displayName){
            this.socket.emit("create-room", {displayName : displayName, description: description, userId : store.state.user.userId});
        }
    },

    joinRoom(roomId){
        
        if(roomId){
            var p = {
                roomId : roomId, 
                userId: store.state.user.userId 
            };
            console.log(`joining. connected : ${this.socket.connected}`);
            this.socket.emit("join-room", p);
        }
    },

    sendMessage(messageData){
        if(messageData.roomId && messageData.message){
            this.socket.emit("send-message", { roomId: messageData.roomId, userId : store.state.user.userId, parentMessage : messageData.parentMessage, message : messageData.message})
        }
    },
    updateRoomMusic(roomId, musicData){
        
        if(musicData){
            this.socket.emit("update-room-music", {roomId : roomId, musicData : musicData});
        }
    }
}