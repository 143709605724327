<template>
    <div class="music-selection-container">
        <div class="music-selection-box">
            <div class="music-selection-title">Media Selection</div>
            <div v-on:click="closeView" class="close-btn"><i class="fa-solid fa-times"></i></div>
            <div class="music-selection-title"></div>
            <div class="music-selection-form">
                <div class="music-selection-row">
                    <div class="music-selection-label">Source</div>
                    <div class="music-selection-value">
                        <select class="source-select" v-model="selectedSource">
                            <option v-for="source in musicSources" :key="source.value" v-bind:value="source.value">
                                {{ source.label }} 
                            </option>
                        </select>
                    </div>
                    
                </div>
                <div v-if="selectedSource == 'radiobrowser'" class="station-search-container">
                    <input v-model="radioBrowserTag" v-on:keyup.enter="searchRadioBrowserStations" type="text" class="station-search-input"/>
                    <div v-on:click="searchRadioBrowserStations" class="station-search-btn">Search</div>
                    <div v-if="searchingStations" class="loading-container fa-spin">

                    </div>
                </div>
                <div v-if="selectedSource == 'radiobrowser'" class="music-selection-row">
                    <div class="music-selection-label">
                        Results : 
                    </div>
                </div>
                <div v-if="selectedSource == 'radiobrowser'" class="radio-stations-container">
                    <div :class="{selected : isSelectedStation(station.id)}" v-on:click="selectStation(station)" v-for="station in radioBrowserStations" :key="station.id" class="radio-station-item">{{ station.displayName }}</div>
                </div>

                <div v-if="selectedSource == 'youtube'" class="youtube-video-container">
                    <input v-model="youtubeVideo" type="text" class="youtube-video-input" placeholder="Video ID"/>
                </div>

                <div v-if="selectedSource == 'youtubelive'" class="youtube-video-container">
                    <input v-model="youtubeStream" type="text" class="youtube-video-input" placeholder="Stream ID"/>
                </div>
            </div>
            <div v-on:click="submitSelection" :class="{enabled: submissionEnabled}" class="submit-btn">
                Select Media
            </div>
        </div>


    </div>
</template>

<script>
import api from '../api/app';

export default {
    data() {
      return {
        musicSources: [
            {
                label: "Radio Browser",
                value: "radiobrowser"
            },
            {
                label: "YouTube",
                value: "youtube"
            },
            {
                label: "YouTube Live Stream",
                value: "youtubelive"
            }
        ],
        selectedSource: "",
        selectedStation: "",
        radioBrowserStations: [],
        radioBrowserTag: "",
        searchingStations: false,
        youtubeVideo: "",
        youtubeStream: ""

      }
    },
    computed: {
        submissionEnabled: function(){
            if(this.selectedSource == 'radiobrowser' && this.selectedStation){
                return true;
            }
            if(this.selectedSource.includes("youtube") && (this.youtubeStream || this.youtubeVideo)){
                return true;
            }
            return false;
        }
    },
    methods:{
        isSelectedStation(id){
            return this.selectedStation == id;
        },
        closeView(){
            
            this.$emit('close');
        },
        searchRadioBrowserStations(){
            var self = this;
            if(this.radioBrowserTag){
                this.searchingStations = true;
                var filter = {
                    limit: 5,
                    by: 'tag',
                    searchterm: this.radioBrowserTag,

                };
                
                api.getRadioBrowserStations(filter).then(function(response) {
                    self.radioBrowserStations = [];
                    if(response.data && response.data.length > 0){
                        var newStations = response.data;
                        newStations.forEach(element => {
                            self.radioBrowserStations.push({
                                source : self.selectedSource,
                                id : element.stationuuid,
                                displayName : element.name,
                                streamUrl : element.url,
                                country: element.country
                            })
                        });
                    }
                    self.searchingStations = false;
                }).catch(function(error){
                    self.searchingStations = false;
                    console.log(error);
                });
                

                /*RadioBrowser.getStations(filter).then(data => {
                    debugger;
                    //this.radioBrowserStations = data.
                    this.searchingStations = false;
                })
                .catch(e => {
                    console.error(e);
                    this.searchingStations = false;
                });*/

            }
        },
        selectStation(station){
            
            this.selectedStation = station.id;
        },
        submitSelection(){
            var data;
            if(this.selectedSource == "radiobrowser"){
                data = this.radioBrowserStations.find(st => st.id == this.selectedStation);

                this.$emit('submit', data);
            }
            else if(this.selectedSource == "youtube"){
                data = {
                    source : "youtube",
                    url: this.youtubeVideo
                }

                this.$emit('submit', data);
            }
            else if(this.selectedSource == "youtubelive"){
                data = {
                    source : "youtubelive",
                    url: this.youtubeStream
                }

                this.$emit('submit', data);
            }

            this.closeView();
        }
    }
}
</script>

<style scoped>


    .music-selection-container{
        width: 100%;
        height:100%;
        background-color: rgb(255, 255, 255 .3);
        display:flex;
        align-items: center;
        justify-content: center;
        position:fixed;
        top:0px;
        left: 0px;
        background-color: rgb(255, 255, 255, .1);
        z-index:5;
    }

    .music-selection-box{
        width: 20em;
        background-color:#333;
        color:white;
        padding: 2em;
        position:relative;
        border-radius:.4em;
    }

    .music-selection-title{
        color: white;
        font-weight:bold;
        font-size: 1.4em;
        margin-bottom: 1.5em;
        text-align: left;
    }

    .music-selection-form{
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content: center;
        gap: 1em;
    }

    .music-selection-row{
        display:flex;
        flex-direction: row;
        width:100%;
        align-items: center;
    }

    .music-selection-label{
        font-weight:bold;
        margin-right:auto;
    }

    .music-selection-value{
        margin-left:auto;
    }

    .station-search-input{
        font-size: 1.5em;
        border-radius: .4em;
        max-width: 60%;
    }

    .source-select{
        font-size:1.5em;
        border-radius: .4em;
    }

    .station-search-container{
        display:flex;
        flex-direction:row;
        width:100%;
        gap:.5em;
        align-items: center;
    }

    .station-search-btn{
        font-weight:bold;
        border: 3px solid rgb(255, 255, 255, .3);
        border-radius: .4em;
        padding: .2em;
        display:flex;
        align-items: center;
        justify-content: center;
        cursor:pointer;
        transition: all 100ms ease-in-out;
    }

    .station-search-btn:hover{
        background-color: rgb(255, 255, 255, .2);
        transition: all 100ms ease-in-out;
    }

    .close-btn{
        position:absolute;
        right:.4em;
        top:.4em;
        padding: .2em;
        align-items: center;
        justify-content: center;
        display:flex;
        border-radius: .5em;
        cursor: pointer;
        font-size: 1.5em;
        transition: all 100ms ease-in-out;
    }

    .close-btn:hover{
        background-color: rgb(255, 255, 255, .2);
        transition: all 100ms ease-in-out;
    }

    .radio-stations-container{
        border-radius: .3em;
        overflow:hidden;
        display:flex;
        flex-direction:column;
    }

    .radio-station-item{
        text-align: left;
        padding: .2em;
        background-color: rgb(255, 255, 255, .4);
        transition: all 100ms ease-in-out;
        cursor: pointer;
        font-family:'DigitalDystopia';
        font-size:1.2em;
    }

    .radio-station-item:hover{
        background-color: rgb(255, 255, 255, .2);
        transition: all 100ms ease-in-out;
    }

    .radio-station-item.selected{
        background-color: rgb(255, 255, 255, .6);
    }

    .submit-btn{
        margin-top:2em;
        font-weight:bold;
        border: 3px solid rgb(255, 255, 255, .3);
        color: #777;
        padding: .2em;
        display:flex;
        align-items: center;
        justify-content: center;
        cursor:not-allowed;
        transition: all 100ms ease-in-out;
        border-radius:.4em;
    }

    .submit-btn.enabled{
        cursor:pointer;
        color:white;
    }

    .submit-btn.enabled:hover{
        background-color: rgb(255, 255, 255, .2);
        transition: all 100ms ease-in-out;
    }

    .youtube-video-container{
        display:flex;
        flex-direction:column;
        justify-content: flex-start;

    }

    .youtube-video-input{
        font-size: 1.5em;
    }
</style>