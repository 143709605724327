<template>
    <div class="front-wrap">
        <div class="front-header">
            <div class="front-title">GriddWork</div>
            <div class="front-tagline">Build the Space you Need</div>
        </div>
        <div v-if="featuredItems.length > 0" class="content-box">
            <div class="content-title feature-title">Featured Content</div>
            <div class="content-items"></div>
        </div>
        <div class="front-features">
            <div class="feature-box new-box">
                <div class="feature-title">Newest Feature</div>
                <div class="feature-subtitle">Media Focused Chat</div>
                <div class="feature-description"></div>
                <div class="feature-text">
                    Create or Join a Room with your choice of Live Music, Video, or Livestream <br><br> 
                    Select your atmosphere with a live music stream from Radio Browser<br>
                    Have a detailed discussion about an online video with friends<br>
                    Create a Room where you can actually have real, focused conversations while watching a live stream
                </div>
            </div>
            <div class="feature-box about-box" v-on:click="goToAbout">
                <div class="feature-title">What is GriddWork?</div>
                <div class="feature-description">Learn about our Social App</div>
            </div>
            <div class="feature-box faq-box" v-on:click="goToFaq">
                <div class="feature-title">FAQ</div>
                <div class="feature-description">The Big Questions</div>
            </div>
        </div>
    </div>

</template>

<script>


export default {
        
      
        data() {
          return {
            featuredItems: []
          };
        },

        methods: {
            goToAbout(){
                this.$router.push({name: "social_about"}).catch(()=>{});
            },
            goToFaq(){
                this.$router.push({name: "social_faq"}).catch(()=>{});
            }


        }
    }

</script>

<style>
    .front-wrap{
        width: 100%;
        height: 100%;
        display:flex;
        flex-direction: column;
        padding: 2em;
        align-items: center;
        justify-content: center;
        color:white;
        box-sizing:border-box;
        padding-bottom: 4em;
    }
    .front-header{
        width: 100%;
        display:flex;
        flex-direction: column;
        gap: 1em;
        text-align:left;
        margin-bottom: 5em;
    }

    .front-title{
        font-size: 4em;
        font-weight: bold;
        text-shadow: 0 0 1px black, 0 0 1px black;
    }

    .front-tagline{
        font-size: 2em;
        font-weight: bold;
    }

    .front-features{
        display:flex;
        flex-direction:row;
        flex-wrap: wrap;
        gap: 3em;
        justify-content: center;

    }

    .content-box{
        box-sizing:border-box;
        display: flex;
        flex-direction: column;
        padding: 1em;
        max-width:90%;
        width: 70em;
        height: 16em;
        text-align: left;
        gap: 1em;
        border: 5px solid white;
        border-radius: .6em;
        margin-bottom: 2em;

    }

    .new-box{
        background-image: url('../../img/swirl.webp');
        background-repeat:no-repeat;
        background-position: center;
        background-size:cover;
    }

    .faq-box{
        background-image: url('../../img/books.webp');
        background-repeat:no-repeat;
        background-position: center;
        background-size:cover;
    }

    .about-box{
        background-image: url('../../img/typing.webp');
        background-repeat:no-repeat;
        background-position: center;
        background-size:cover;
    }
    .feature-box{
        box-sizing: border-box;
        display:flex;
        flex-direction:column;
        padding: 1em;
        gap: 1em;
        text-align:left;
        width: 19em;
        height: 22em;
        border: 5px solid white;
        border-radius: .6em;
        cursor: pointer;
        transition: all 200ms ease-in-out;
    }

    .feature-box:hover{
        box-shadow: 0px 0px 4px white, 0px 0px 7px white;
        transition: all 200ms ease-in-out;
        color: black;
        
        
    }

    .feature-box:hover > .feature-title, .feature-box:hover > .feature-subtitle, .feature-box:hover > .feature-description{
        text-shadow: 0 0 1px white, 0 0 1px white, 0 0 2px white, 0 0 2px white;
        transition: all 200ms ease-in-out;
    }

    .feature-box:hover > .feature-text{
        transition: all 200ms ease-in-out;
        text-shadow: 0 0 1px white, 0 0 2px white, 0 0 1px white;
    }


    .feature-title{
        font-size: 1.5em;
        font-weight:bold;
        text-shadow: 0 0 1px black, 0 0 1px black, 0 0 2px black, 0 0 2px black;
    }

    .feature-subtitle{
        font-size: 1.2em;
        font-weight:bold;
        text-shadow: 0 0 1px black, 0 0 1px black;

    }

    .feature-description{
        font-size: 1.2em;
        font-weight: bold;
        text-shadow: 0 0 1px black, 0 0 1px black;
    }

    .feature-text{
        text-shadow: 0 0 1px black, 0 0 1px black;
        font-size: 1em;

    }
</style>